import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label", "amount"];

  discount() {
    const amount = this.amountTarget.value;
    this.amountTarget.value = amount * 0.8;
    this.labelTarget.value = this.labelTarget.value + " (20% Degustationsrabatt)";
  }
}
